import { VariantOptions } from '@definitions/artworks.types';
import { DIRECTION, NOT_SHIPPED, SELECTED_GALLERIES, SHIPPED, SORT, ORDER_ID } from '@modules/orders/orders.constants';

export interface BookShippingData {
  orderId: string;
  artworkId: string;
  variantId?: string;
  quantityIndex: string;
  fedexDate?: string;
}

export interface SetEditionData {
  orderId: string;
  artworkId: string;
  variantId?: string;
  quantityIndex: string;
  edition: number;
}

export interface SetOrderStatus {
  orderId: string;
  artworkId: string;
  variantId?: string;
  quantityIndex: string;
  shippingStatus: ORDER_STATUS;
}

export interface GetOrderDetailData {
  orderId: string;
  artworkId?: string;
  variantId?: string;
  quantityIndex: string;
}

export interface OrderDimensions {
  width: string;
  height: string;
  depth: string;
}

export interface Edition {
  id: number;
  title: string;
}

export interface Order {
  orderId: string;
  isAdmin: boolean;
  galleryName: string;
  edition?: number;
  editions: Edition[];
  isEdition: boolean;
  platformInventoryId: string;
  artworkId: string;
  variantId: string;
  quantityIndex: string;
  galleryInventoryId: string;
  artistName: string;
  title: string;
  orderDate: string;
  deadlineDate: string;
  shippingStatus: ORDER_STATUS;
  invoiceLink: string;
  packingSlip: string;
  isFedex: boolean;
  hasDownloadedDocuments: boolean;
  imageUploadRequired: boolean;
  fedexShippingLabel: string;
  shippingType: string | null;
  optionValues: VariantOptions[];
  dimensions: OrderDimensions;
}

export interface ShippingImage {
  url: string;
  id: string;
}

export interface AdditionalData {
  artshipperShipmentId: string;
  artshipperShipmentQuoteId: string;
  artshipperStatus: string;
  artshipperStatusDate: string;
  fedexTrackingNumber: string;
  isStatusManual: boolean;
}

export interface DetailedOrder extends Order {
  artworkPrice: string;
  currencyCode: string;
  addressFirstName: string;
  addressLastName: string;
  addressStreet1: string;
  image: string;
  shippingImages: ShippingImage[];
  addressStreet2: string;
  addressCity: string;
  addressZip: string;
  addressState: string;
  addressCountry: string;
  addressEmail: string;
  addressPhone: string;
  imageUploadRequired: boolean;
  optionValues: VariantOptions[];
  additionalData?: AdditionalData;
}

export interface OrdersMeta {
  count: number;
  hasNext: boolean;
  page: number;
  galleries: string[];
  [SELECTED_GALLERIES]: string[];
  [SHIPPED]: boolean;
  [NOT_SHIPPED]: boolean;
  [SORT]: SORT_TYPES;
  [DIRECTION]: SORT_DIRECTION;
  [ORDER_ID]: string;
}

export interface OrdersState {
  orders: Order[];
  orderDetail?: DetailedOrder;
  loading: boolean;
  meta: OrdersMeta;
}

export interface FetchPayload {
  results: Order[];
  meta: { galleries: string[] };
  [SELECTED_GALLERIES]: string[];
  [SHIPPED]: boolean;
  [NOT_SHIPPED]: boolean;
  [SORT]: SORT_TYPES;
  [DIRECTION]: SORT_DIRECTION;
  [ORDER_ID]: string;
  count: number;
  next: string;
  page: number;
}

export enum SORT_TYPES {
  ID = 'id',
  ORDER_DATE = 'date',
}

export enum SORT_DIRECTION {
  ASC = 'asc',
  DESC = 'desc',
}

export interface FetchOrderRequest {
  page?: number;
  [SELECTED_GALLERIES]?: string[];
  [SHIPPED]?: boolean;
  [NOT_SHIPPED]?: boolean;
  [SORT]?: SORT_TYPES;
  [DIRECTION]?: SORT_DIRECTION;
  [ORDER_ID]?: string;
}

export interface UploadImagesData {
  orderId: string;
  file?: File[] | null;
  artworkId: string;
  variantId?: string;
  quantityIndex: string;
}

export interface RemoveImageData {
  url?: string;
  orderId: string;
  artworkId: string;
  variantId?: string;
  quantityIndex: string;
  id: string;
}

export enum ORDER_STATUS {
  PENDING = 'Pending',
  SHIPPED = 'Shipped',
  PARTIALLY_SHIPPED = 'Partially Shipped',
  AWAITING_PAYMENT = 'Awaiting Payment',
  RETURN_INITIATED = 'Return Initiated',
  RETURN_CONFIRMED = 'Return Confirmed',
  AWAITING_FULFILLMENT = 'Awaiting Fulfillment',
  MANUAL_VERIFICATION_REQUIRED = 'Manual Verification Required',
  DECLINED = 'Declined',
  DISPUTED = 'Disputed',
  REFUNDED = 'Refunded',
  PARTIALLY_REFUNDED = 'Partially Refunded',
  CANCELLED = 'Cancelled',
  COMPLETED = 'Completed',
}
