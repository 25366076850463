import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  AddressFormData,
  Customer,
  CustomerAddress,
  CustomerOrder,
  GetOrderPayload,
  RegisterPayload,
  UpdatePayload,
} from '@definitions/customer.types';
import { ResetPasswordPayload, ResetPasswordConfirmPayload } from '@definitions/common.types';
import api from '@services/api';
import { STORE_FRONT_KEY } from '@modules/plp';

const PREFIX = 'CUSTOMER';

export const registerCustomer = createAsyncThunk<Customer, RegisterPayload>(
  `${PREFIX}/REGISTER_CUSTOMER`,
  async (formData, { rejectWithValue }) => {
    try {
      const { data } = await api.post('/customers/', formData);

      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return rejectWithValue(e.response?.data);
      }
    }
  }
);

export const resetPassword = createAsyncThunk<void, ResetPasswordPayload>(
  `${PREFIX}/RESET_PASSWORD`,
  async (formData) => {
    const { data } = await api.post('/customers/password-reset/', formData);

    return data;
  }
);

export const changePassword = createAsyncThunk<ResetPasswordConfirmPayload, ResetPasswordConfirmPayload>(
  `${PREFIX}/RESET_PASSWORD_CONFIRM`,
  async (formData) => {
    const { data } = await api.post('/customers/password-reset/confirm/', formData);

    return data;
  }
);

export const getCustomer = createAsyncThunk<Customer, void>(`${PREFIX}/GET_CUSTOMER`, async () => {
  const { data } = await api.get('/customers/me/', { params: { storefrontKey: STORE_FRONT_KEY } });

  return data;
});

export const updateCustomer = createAsyncThunk<Customer, UpdatePayload>(
  `${PREFIX}/UPDATE_CUSTOMER`,
  async (formData, { rejectWithValue }) => {
    try {
      const { data } = await api.put('/customers/me/', formData, { params: { storefrontKey: STORE_FRONT_KEY } });

      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return rejectWithValue(e.response?.data);
      }
    }
  }
);

export const getOrders = createAsyncThunk<CustomerOrder[], void>(`${PREFIX}/GET_ORDERS`, async () => {
  const { data } = await api.get('/customers/me/orders/', { params: { storefrontKey: STORE_FRONT_KEY } });

  return data;
});

export const getOrder = createAsyncThunk<CustomerOrder, GetOrderPayload>(
  `${PREFIX}/GET_ORDER`,
  async ({ slug, token }, { rejectWithValue }) => {
    try {
      const params = token ? { token } : { storefrontKey: STORE_FRONT_KEY };
      const { data } = await api.get(`/customers/me/orders/${slug}/`, {
        params,
      });

      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return rejectWithValue(e.response);
      }
    }
  }
);

export const cancelOrder = createAsyncThunk<CustomerOrder, { orderId: number }>(
  `${PREFIX}/CANCEL_ORDER`,
  async ({ orderId }, { rejectWithValue }) => {
    try {
      const { data } = await api.post(`/customers/me/orders/${orderId}/cancel/`, {});

      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return rejectWithValue(e.response);
      }
    }
  }
);

export const getAddresses = createAsyncThunk<CustomerAddress[], void>(`${PREFIX}/GET_ADDRESSES`, async () => {
  const { data } = await api.get('/customers/me/addresses/', { params: { storefrontKey: STORE_FRONT_KEY } });

  return data;
});

export const getAddress = createAsyncThunk<CustomerAddress, { addressId: string }>(
  `${PREFIX}/GET_ADDRESS`,
  async ({ addressId }, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`/customers/me/addresses/${addressId}/`, {
        params: { storefrontKey: STORE_FRONT_KEY },
      });

      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return rejectWithValue(e.response);
      }
    }
  }
);

export const removeAddress = createAsyncThunk<{ addressId: number }, { addressId: number }>(
  `${PREFIX}/REMOVE_ADDRESS`,
  async ({ addressId }) => {
    await api.delete(`/customers/me/addresses/${addressId}/`, {
      params: { storefrontKey: STORE_FRONT_KEY },
    });

    return { addressId };
  }
);

export const updateAddress = createAsyncThunk<CustomerAddress, CustomerAddress>(
  `${PREFIX}/UPDATE_ADDRESS`,
  async (formData) => {
    const { data } = await api.put(`/customers/me/addresses/${formData.id}/`, formData, {
      params: { storefrontKey: STORE_FRONT_KEY },
    });

    return data;
  }
);

export const createAddress = createAsyncThunk<CustomerAddress, AddressFormData>(
  `${PREFIX}/CREATE_ADDRESS`,
  async (formData) => {
    const { data } = await api.post('/customers/me/addresses/', formData, {
      params: { storefrontKey: STORE_FRONT_KEY },
    });

    return data;
  }
);
