import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { ShippingCountryPayload, ShippingState } from '@definitions/shipping.types';
import { DEFAULT_SHIPPING_COUNTRY_CODE } from '@modules/shipping/shipping.constants';

import * as actions from './shipping.actions';

export const initialState: ShippingState = {
  shippingCountryCode: DEFAULT_SHIPPING_COUNTRY_CODE,
};

const handleSetShippingCountryCode = (state: ShippingState, { payload }: PayloadAction<ShippingCountryPayload>) => {
  state.shippingCountryCode = payload.shippingCountryCode;
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actions.setShippingCountryCode, handleSetShippingCountryCode);
});
