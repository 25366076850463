import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { Footer, FooterState } from '@definitions/footer.types';

import * as actions from './footer.actions';

export const initialState: FooterState = { links: null };

const handleFetchFooterLinks = (state: FooterState, { payload }: PayloadAction<Footer>) => {
  state.links = payload;
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actions.fetchFooterLinks.fulfilled, handleFetchFooterLinks);
});
