import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { findIndex, propEq, update } from 'ramda';

import { ScrollRestoration, UiState } from '@definitions/ui.types';
import { SUBLEVEL } from '@components/header/header.constants';
import { MEASURES } from '@modules/ui/ui.constants';

import * as actions from './ui.actions';

export const initialState: UiState = {
  headerSublevelType: null,
  headerSublevelOpen: false,
  preventRedirectAfterLogin: false,
  measure: MEASURES.INCH,
  headerMobileMenuOpen: false,
  scrollRestorations: [],
  previousPageUrl: null,
  scrollRestored: false,
};

const handleOpenHeaderSublevel = (
  state: UiState,
  {
    payload: { sublevel, preventRedirectAfterLogin = false },
  }: PayloadAction<{ sublevel: SUBLEVEL; preventRedirectAfterLogin?: boolean }>
) => {
  state.headerSublevelType = sublevel;
  state.headerSublevelOpen = true;
  state.preventRedirectAfterLogin = preventRedirectAfterLogin;
};

const handleCloseHeaderSublevel = (state: UiState) => {
  state.headerSublevelType = null;
  state.headerSublevelOpen = false;
};

const handleToggleMeasure = (state: UiState) => {
  state.measure = state.measure === MEASURES.INCH ? MEASURES.CM : MEASURES.INCH;
};

const handleSetHeaderMobileMenuOpen = (state: UiState, { payload: isHeaderMobileMenuOpen }: PayloadAction<boolean>) => {
  state.headerMobileMenuOpen = isHeaderMobileMenuOpen;
};
const handleSetScrollRestored = (state: UiState, { payload }: PayloadAction<boolean>) => {
  state.scrollRestored = payload;
};

const handleSetScrollRestoration = (
  state: UiState,
  { payload: scrollRestoration }: PayloadAction<ScrollRestoration>
) => {
  const index = findIndex(propEq('url', scrollRestoration.url), state.scrollRestorations);
  state.previousPageUrl = scrollRestoration.url;

  if (index === -1) {
    state.scrollRestorations.push(scrollRestoration);
    return;
  }

  state.scrollRestorations = update(index, scrollRestoration, state.scrollRestorations);
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actions.openHeaderSublevel, handleOpenHeaderSublevel);
  builder.addCase(actions.closeHeaderSublevel, handleCloseHeaderSublevel);
  builder.addCase(actions.toggleMeasure, handleToggleMeasure);
  builder.addCase(actions.setHeaderMobileMenuOpen, handleSetHeaderMobileMenuOpen);
  builder.addCase(actions.setScrollRestoration, handleSetScrollRestoration);
  builder.addCase(actions.setScrollRestored, handleSetScrollRestored);
});
