import { createAsyncThunk } from '@reduxjs/toolkit';

import { AddCartPayload, Cart, RemoveCartPayload, UpdateCartPayload } from '@definitions/shoppingCart.types';
import { GlobalState } from '@modules/createStore';
import api from '@services/api';
import { QueryParam } from '@definitions/common.types';
import { getFirstParam } from '@utils/helpers';

import { createUpdateAbandonedCart, removeAbandonedCart } from './shoppingCart.helpers';

const PREFIX = 'SHOPPING_CART';

export const fetchCart = createAsyncThunk<
  { cart: Cart | null; cartRecoveryId: QueryParam },
  QueryParam,
  { state: GlobalState }
>(`${PREFIX}/FETCH_CART`, async (cartRecoveryId, { getState, rejectWithValue }) => {
  const cartId = getFirstParam(cartRecoveryId || getState().shoppingCart.cartId);

  try {
    if (cartId) {
      const { data }: { data: Cart } = await api.get(`/carts/${cartId}/`);

      await createUpdateAbandonedCart(data);

      return { cart: data, cartRecoveryId };
    }

    return rejectWithValue({ cart: null, cartRecoveryId });
  } catch (e) {
    return rejectWithValue({ cart: null, cartRecoveryId });
  }
});

export const getCheckoutUrl = createAsyncThunk<string, void, { state: GlobalState }>(
  `${PREFIX}/GET_CHECKOUT_URL`,
  async (nothing, thunkAPI) => {
    const { cartId } = thunkAPI.getState().shoppingCart;

    const { data } = await api.get(`/carts/${cartId || ''}/checkout-url`);

    return data.checkoutUrl;
  }
);

export const updateCartItem = createAsyncThunk<Cart, UpdateCartPayload, { state: GlobalState }>(
  `${PREFIX}/UPDATE_CART_ITEM`,
  async ({ lineItem: { productId, variantId, quantity } }, thunkAPI) => {
    const {
      id: cartId,
      lineItems: { physicalItems },
    } = thunkAPI.getState().shoppingCart.cart;
    const [shoppingCartItem] = physicalItems.filter(
      (item) => item.productId === productId && item.variantId === variantId
    );

    const { data }: { data: Cart } = await api.put(`/carts/${cartId}/items/${shoppingCartItem.id}/`, {
      lineItem: { productId, variantId, quantity },
    });

    if (data) {
      await createUpdateAbandonedCart(data);
    }

    if (!data) {
      await removeAbandonedCart(cartId || '');
    }

    return data;
  }
);

export const addCartItem = createAsyncThunk<Cart, AddCartPayload, { state: GlobalState }>(
  `${PREFIX}/ADD_CART_ITEM`,
  async ({ physicalItem, giftCertificate }, thunkAPI) => {
    const lineItems = physicalItem ? [{ ...physicalItem }] : [];
    const giftCertificates = giftCertificate ? [giftCertificate] : [];
    const { cartId } = thunkAPI.getState().shoppingCart;

    if (cartId) {
      const { data }: { data: Cart } = await api.post(`/carts/${cartId}/items/`, { lineItems, giftCertificates });
      await createUpdateAbandonedCart(data);
      return data;
    }

    const { data }: { data: Cart } = await api.post('/carts/', { lineItems, giftCertificates });
    await createUpdateAbandonedCart(data);
    return data;
  }
);

export const removeCartItem = createAsyncThunk<Cart, RemoveCartPayload, { state: GlobalState }>(
  `${PREFIX}/REMOVE_CART_ITEM`,
  async ({ itemId }, thunkAPI) => {
    const { cartId } = thunkAPI.getState().shoppingCart;
    const { data }: { data: Cart } = await api.delete(`/carts/${cartId || ''}/items/${itemId}/`);

    if (data) {
      await createUpdateAbandonedCart(data);
    }

    if (!data) {
      await removeAbandonedCart(cartId || '');
    }

    return data;
  }
);
