import { PayloadAction } from '@reduxjs/toolkit';
import { findIndex, pipe, propEq, append, pathOr } from 'ramda';

import {
  ArtworkDynamicData,
  ArtworksState,
  ChangeGridSizePayload,
  FetchArtworksPayload,
  FetchFavoriteArtworksPayload,
  ResponseAddFavoritePayload,
} from '@definitions/artworks.types';

import { GRID_SIZE } from './plp.constants';

export const initialState: ArtworksState = {
  gridSize: GRID_SIZE.MEDIUM,
  artworks: [],
  loading: false,
  favoriteArtworks: [],
  artworkDynamicData: {},
  currentPage: 1,
  meta: { hasNext: false, count: 0, page: 1, artworkData: { title: '', description: '' } },
};

export const changeGridSize = (state: ArtworksState, { payload }: PayloadAction<ChangeGridSizePayload>) => {
  state.gridSize = payload.gridSize;
};

export const initialFetchArtworks = (state: ArtworksState, data: any) => {
  state.loading = true;
  const infiniteLoader = pathOr(false, ['meta', 'arg', 'infiniteLoader'], data);
  if (!infiniteLoader) {
    state.artworks = [];
  }
};

export const fetchArtworks = (state: ArtworksState, { payload }: PayloadAction<FetchArtworksPayload>) => {
  state.meta = payload.meta;
  state.loading = false;

  if (payload.infiniteLoader) {
    state.artworks = state.artworks.concat(payload.artworks);
    return;
  }

  state.artworks = payload.artworks;
};

export const fetchFavoriteArtworks = (
  state: ArtworksState,
  { payload }: PayloadAction<FetchFavoriteArtworksPayload>
) => {
  state.favoriteArtworks = payload.artworks;
};

export const setInitialArtworks = (state: ArtworksState, { payload }: PayloadAction<FetchArtworksPayload>) => {
  state.artworks = payload.artworks;
  state.meta = payload.meta;
};

export const resetArtworks = (state: ArtworksState) => {
  state.artworks = [];
  state.meta = { hasNext: false, count: 0, page: 1, artworkData: { title: '', description: '' } };
};

export const updateKeyBySlug = (key: string, value: boolean) => (
  state: ArtworksState,
  { payload }: PayloadAction<ResponseAddFavoritePayload>
) => {
  const updateArtworksAt = (index: number) => {
    state.artworks[index] = { ...state.artworks[index], [key]: value };
  };

  const updateFavoriteArtworksAt = (index: number) => {
    if (index >= 0) {
      state.favoriteArtworks[index] = { ...state.favoriteArtworks[index], [key]: value };
      return;
    }

    const artworkIndex = findIndex(propEq('slug', payload.slug), state.artworks);
    state.favoriteArtworks = append({ ...state.artworks[artworkIndex], [key]: value }, state.favoriteArtworks);
  };

  pipe(findIndex(propEq('slug', payload.slug)), updateArtworksAt)(state.artworks);

  if (key === 'favorite') {
    pipe(findIndex(propEq('slug', payload.slug)), updateFavoriteArtworksAt)(state.favoriteArtworks);
  }
};

export const setArtworkDynamicData = (state: ArtworksState, { payload }: PayloadAction<ArtworkDynamicData>) => {
  state.artworkDynamicData = payload;
};

export const setCurrentPage = (state: ArtworksState, { payload }: PayloadAction<number>) => {
  state.currentPage = payload;
};
