import { createSelector } from '@reduxjs/toolkit';

import { GdprConsentsState } from '@definitions/gdprConsents.types';

import { GlobalState } from '../createStore';

import { initialState } from './gdprConsents.reducer';

export const selectGdprConsentsDomain = (state: GlobalState) => state.gdprConsents;

export const selectShowModal = createSelector(selectGdprConsentsDomain, (state: GdprConsentsState) => state.showModal);

export const selectGdprConsent = createSelector(
  selectGdprConsentsDomain,
  (state: GdprConsentsState) => state.gdprConsent
);

export const selectGdprConsents = createSelector(
  selectGdprConsentsDomain,
  (state: GdprConsentsState) => state.gdprConsents || initialState.gdprConsents
);
