import { artworksDomain } from '@modules/artworks/artworks.selectors';
import { groupingsDomain } from '@modules/groupingArtworks/groupingArtworks.selectors';
import { tradeDomain } from '@modules/tradeArtworks/tradeArtworks.selectors';

export enum GRID_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum GRID_SIZE_ORDER {
  small = 1,
  medium,
  large,
}

export enum CUSTOM_FIELDS {
  LAYOUT_L = 'layoutL',
  LAYOUT_M = 'layoutM',
}

export enum FILTER_OPTIONS {
  FAVORITES = 'favorites',
  WAITLIST = 'waitlist',
}

export enum LAYOUT_M_OPTIONS {
  COLUMN_1 = 'column_1',
  COLUMN_1_LEFT_MARGIN = 'column_1_left_margin',
  COLUMN_1_RIGHT_MARGIN = 'column_1_right_margin',
  COLUMN_2 = 'column_2',
}

export enum LAYOUT_L_OPTIONS {
  p25 = '25%',
  p50 = '50%',
  p75 = '75%',
  p100 = '100%',
}

export const ARTWORKS_LIMIT = 24;

export const STORE_FRONT_KEY = 'SHOP_TOKEN';

export const DYNAMIC_DATA = [
  'unframedHeight',
  'unframedWidth',
  'unframedDepth',
  'framedDepth',
  'framedHeight',
  'framedWidth',
  'description',
  'price',
  'inventoryLevel',
  'isFramed',
  'showSpecs',
  'specsDescription',
  'seo',
  'images',
  'urlStandard',
];

export const ARTWORKS_PAGE_PREFIX = 'artworks-page-';
export const HIDDEN_BOTTOM = 'artworks-hidden-bottom';

export const ACTION_PREFIX = {
  GROUPINGS: 'GROUPING_ARTWORKS',
  TRADE: 'TRADE_ARTWORKS',
  ARTWORKS: 'ARTWORKS',
};

export enum PLP_VARIANT {
  ARTWORKS = 'artworks',
  TRADE = 'trade',
  GROUPINGS = 'groupings',
}

export const PLP_PREFIXES = {
  trade: ACTION_PREFIX.TRADE,
  groupings: ACTION_PREFIX.GROUPINGS,
  artworks: ACTION_PREFIX.ARTWORKS,
};

export const PLP_DOMAINS = {
  trade: tradeDomain,
  groupings: groupingsDomain,
  artworks: artworksDomain,
};
