import { createSelector } from '@reduxjs/toolkit';

import { UiState } from '@definitions/ui.types';

import { GlobalState } from '../createStore';

export const selectUiDomain = (state: GlobalState) => state.ui;

export const selectHeaderSublevelType = createSelector(selectUiDomain, (state: UiState) => state.headerSublevelType);
export const selectHeaderSublevelOpen = createSelector(selectUiDomain, (state: UiState) => state.headerSublevelOpen);
export const selectPreventRedirectAfterLogin = createSelector(
  selectUiDomain,
  (state: UiState) => state.preventRedirectAfterLogin
);
export const selectMeasure = createSelector(selectUiDomain, (state: UiState) => state.measure);
export const selectScrollRestorations = createSelector(selectUiDomain, (state: UiState) => state.scrollRestorations);
export const selectPreviousPageUrl = createSelector(selectUiDomain, (state: UiState) => state.previousPageUrl);
export const selectScrollRestored = createSelector(selectUiDomain, (state: UiState) => state.scrollRestored);
export const selectHeaderMobileMenuOpen = createSelector(
  selectUiDomain,
  (state: UiState) => state.headerMobileMenuOpen
);
