export const DEFAULT_SHIPPING_COUNTRY_CODE = 'US';

export const ALLOWED_SHIPPING_COUNTRIES = [
  { name: 'Great Britain', code: 'GB' },
  { name: 'France', code: 'FR' },
  { name: 'Italy', code: 'IT' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Germany', code: 'DE' },
  { name: 'Spain', code: 'ES' },
  { name: 'Austria', code: 'AT' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Norway', code: 'NO' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Australia', code: 'AU' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Dubai', code: 'DU' },
  { name: 'Abu Dhabi', code: 'AZ' },
  { name: 'India', code: 'IN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Japan', code: 'JP' },
  { name: 'South Korea', code: 'KR' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Ireland', code: 'IE' },
  { name: 'United States', code: 'US' },
  { name: 'Taiwan', code: 'TW' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Hungary', code: 'HU' },
];
