export enum FILTER_NAME {
  ARTIST = 'artist',
  PRICE = 'price',
  TYPE = 'type',
  SUB_CATEGORY = 'sub_category',
  SIZE = 'size',
}

export enum SORT_NAME {
  SORT = 'sort',
  DIRECTION = 'direction',
}

export const SORT_ORDER = [SORT_NAME.SORT, SORT_NAME.DIRECTION];

export const FILTER_ORDER = [
  FILTER_NAME.PRICE,
  FILTER_NAME.SIZE,
  FILTER_NAME.TYPE,
  FILTER_NAME.SUB_CATEGORY,
  FILTER_NAME.ARTIST,
];

export const DEFAULT_FILTER_NAMES: Record<string, string> = {
  [FILTER_NAME.ARTIST]: 'Artist',
  [FILTER_NAME.PRICE]: 'Price',
  [FILTER_NAME.TYPE]: 'Category',
  [FILTER_NAME.SUB_CATEGORY]: 'Medium',
  [FILTER_NAME.SIZE]: 'Size',
};

export const FILTER_CONTAINER_TEST_ID = 'filter-container-test-id';

export const MIN_PRICE = 'minPrice';
export const MAX_PRICE = 'maxPrice';
