import { createSelector } from '@reduxjs/toolkit';

import { ShippingState } from '@definitions/shipping.types';

import { GlobalState } from '../createStore';

export const selectShippingDomain = (state: GlobalState) => state.shipping;

export const selectShippingCountryCode = createSelector(
  selectShippingDomain,
  (state: ShippingState) => state.shippingCountryCode
);
