import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';

const api = applyCaseMiddleware(
  axios.create({
    baseURL: '/api/',
  }),
  {
    preservedKeys: [
      'itemId',
      'productId',
      'variantId',
      'salePrice',
      'baseAmount',
      'contactID',
      'imageUrl',
      'productUrl',
      'eventType',
      'stringSearch',
      'contentType',
      'contentName',
      'contentIds',
      'numItems',
    ],
  }
);

export default api;
