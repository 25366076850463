import { createSelector } from '@reduxjs/toolkit';

import { ArtworksState } from '@definitions/artworks.types';

import { GlobalState } from '../createStore';

export const plpDomain = (state: GlobalState) => state.artworks;

export const selectGridSize = (artworksDomain: (state: GlobalState) => ArtworksState) =>
  createSelector(artworksDomain, (state: ArtworksState) => state.gridSize);

export const selectArtworks = (artworksDomain: (state: GlobalState) => ArtworksState) =>
  createSelector(artworksDomain, (state: ArtworksState) => state.artworks);

export const selectLoading = (artworksDomain: (state: GlobalState) => ArtworksState) =>
  createSelector(artworksDomain, (state: ArtworksState) => state.loading);

export const selectCurrentPage = (artworksDomain: (state: GlobalState) => ArtworksState) =>
  createSelector(artworksDomain, (state: ArtworksState) => state.currentPage);

export const selectFavoriteArtworks = (artworksDomain: (state: GlobalState) => ArtworksState) =>
  createSelector(artworksDomain, (state: ArtworksState) => state.favoriteArtworks);

export const selectMeta = (artworksDomain: (state: GlobalState) => ArtworksState) =>
  createSelector(artworksDomain, (state: ArtworksState) => state.meta);

export const selectArtworkDynamicData = (artworksDomain: (state: GlobalState) => ArtworksState) =>
  createSelector(artworksDomain, (state: ArtworksState) => state.artworkDynamicData);
