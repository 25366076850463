import { createSelector } from '@reduxjs/toolkit';

import { CustomerState } from '@definitions/customer.types';

import { GlobalState } from '../createStore';

export const selectCustomerDomain = (state: GlobalState) => state.customer;

export const selectCustomer = createSelector(selectCustomerDomain, (state: CustomerState) => state.customer);
export const selectOrders = createSelector(selectCustomerDomain, (state: CustomerState) => state.orders);
export const selectOrder = createSelector(selectCustomerDomain, (state: CustomerState) => state.order);
export const selectAddresses = createSelector(selectCustomerDomain, (state: CustomerState) => state.addresses);
export const selectAddress = createSelector(selectCustomerDomain, (state: CustomerState) => state.address);
