import { createAction } from '@reduxjs/toolkit';

import { SUBLEVEL } from '@components/header/header.constants';
import { ScrollRestoration } from '@definitions/ui.types';

const PREFIX = 'UI';

export const openHeaderSublevel = createAction<{ sublevel: SUBLEVEL; preventRedirectAfterLogin?: boolean }>(
  `${PREFIX}/OPEN_HEADER_SUBLEVEL`
);
export const closeHeaderSublevel = createAction<void>(`${PREFIX}/CLOSE_HEADER_SUBLEVEL`);
export const toggleMeasure = createAction<void>(`${PREFIX}/TOGGLE_MEASURE`);
export const setHeaderMobileMenuOpen = createAction<boolean>(`${PREFIX}/SET_HEADER_MOBILE_MENU_OPEN`);
export const setScrollRestoration = createAction<ScrollRestoration>(`${PREFIX}/SET_SCROLL_RESTORATION`);
export const setScrollRestored = createAction<boolean>(`${PREFIX}/SET_SCROLL_RESTORED`);
