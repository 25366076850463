import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { propEq, reject } from 'ramda';

import { Customer, CustomerAddress, CustomerOrder, CustomerState } from '@definitions/customer.types';
import { ORDER_STATUS } from '@definitions/order.types';
import {
  ADDRESS_FORM_ADDRESS_1,
  ADDRESS_FORM_ADDRESS_2,
  ADDRESS_FORM_CITY,
  ADDRESS_FORM_COMPANY,
  ADDRESS_FORM_COUNTRY,
  ADDRESS_FORM_COUNTRY_CODE,
  ADDRESS_FORM_FIRST_NAME,
  ADDRESS_FORM_LAST_NAME,
  ADDRESS_FORM_PHONE,
  ADDRESS_FORM_POSTAL_CODE,
  ADDRESS_FORM_STATE,
} from '@modules/customer/customer.constants';

import * as actions from './customer.actions';

const initialAddress = {
  [ADDRESS_FORM_ADDRESS_1]: '',
  [ADDRESS_FORM_ADDRESS_2]: '',
  [ADDRESS_FORM_COMPANY]: '',
  [ADDRESS_FORM_LAST_NAME]: '',
  [ADDRESS_FORM_FIRST_NAME]: '',
  [ADDRESS_FORM_COUNTRY]: '',
  [ADDRESS_FORM_CITY]: '',
  [ADDRESS_FORM_POSTAL_CODE]: '',
  [ADDRESS_FORM_PHONE]: '',
  [ADDRESS_FORM_STATE]: '',
  [ADDRESS_FORM_COUNTRY_CODE]: '',
  id: 0,
};

export const initialState: CustomerState = {
  customer: {
    email: '',
    id: 1,
    favourites: [],
    firstName: '',
    lastName: '',
    phone: '',
    storeCreditAmounts: [],
    addresses: [],
    recentOrders: [],
  },
  orders: [],
  order: {
    cartId: '',
    currencyCode: '',
    id: 0,
    status: ORDER_STATUS.PENDING,
    dateCreated: '',
    subtotalExTax: '',
    subtotalIncTax: '',
    products: [],
    giftCertificates: [],
    totalExTax: '',
    totalIncTax: '',
    shippingCostIncTax: '',
    discountAmount: '',
    billingAddress: initialAddress,
    shippingAddresses: [initialAddress],
    subtotalTax: '',
    paymentMethod: '',
  },
  addresses: [],
  address: initialAddress,
};

const handleFetchedCustomer = (state: CustomerState, { payload }: PayloadAction<Customer>) => {
  state.customer = payload;
};

const handleFetchedOrders = (state: CustomerState, { payload }: PayloadAction<CustomerOrder[]>) => {
  state.orders = payload;
};

const handleFetchedOrder = (state: CustomerState, { payload }: PayloadAction<CustomerOrder>) => {
  state.order = payload;
};

const handleFetchedAddresses = (state: CustomerState, { payload }: PayloadAction<CustomerAddress[]>) => {
  state.addresses = payload;
};

const handleRemovedAddress = (state: CustomerState, { payload }: PayloadAction<{ addressId: number }>) => {
  state.addresses = reject(propEq('id', payload.addressId), state.addresses);
};

const handleFetchedAddress = (state: CustomerState, { payload }: PayloadAction<CustomerAddress>) => {
  state.address = payload;
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actions.getCustomer.fulfilled, handleFetchedCustomer);
  builder.addCase(actions.getOrders.fulfilled, handleFetchedOrders);
  builder.addCase(actions.getOrder.fulfilled, handleFetchedOrder);
  builder.addCase(actions.cancelOrder.fulfilled, handleFetchedOrder);
  builder.addCase(actions.getAddresses.fulfilled, handleFetchedAddresses);
  builder.addCase(actions.getAddress.fulfilled, handleFetchedAddress);
  builder.addCase(actions.updateAddress.fulfilled, handleFetchedAddress);
  builder.addCase(actions.createAddress.fulfilled, handleFetchedAddress);
  builder.addCase(actions.removeAddress.fulfilled, handleRemovedAddress);
});
