import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  ArtworkDynamicData,
  ChangeGridSizePayload,
  FetchArtworksPayload,
  FetchArtworksState,
  RequestAddFavoritePayload,
  RequestAddWaitlistPayload,
  ResponseAddFavoritePayload,
  ResponseAddWaitlistPayload,
  RequestRemoveWaitlistPayload,
  ResponseRemoveWaitlistPayload,
  SetInitialArtworkPayload,
  FetchGroupingArtworksState,
} from '@definitions/artworks.types';
import { ACTION_PREFIX, ARTWORKS_LIMIT, STORE_FRONT_KEY } from '@modules/plp/plp.constants';

import api from '../../services/api';

export const changeGridSize = (prefix: string) => createAction<ChangeGridSizePayload>(`${prefix}/CHANGE_GRID_SIZE`);

export const setInitialArtworks = (prefix: string) =>
  createAction<SetInitialArtworkPayload>(`${prefix}/SET_INITIAL_ARTWORKS`);

const fetchTradeArtworksFunc = async ({
  page = 1,
  filterOptions = {},
  search = '',
  infiniteLoader = false,
  offset,
  limit = ARTWORKS_LIMIT,
}: FetchArtworksState) => {
  const { price, inputPrice, ...restFilterOptions } = filterOptions;
  if (filterOptions.inputPrice) {
    if (price) {
      restFilterOptions.price = typeof price === 'string' ? [price, inputPrice] : [...price, inputPrice];
    } else {
      restFilterOptions.price = inputPrice;
    }
  }

  if (price && !inputPrice) {
    restFilterOptions.price = price;
  }

  const query = {
    limit: limit,
    offset: offset || limit * (page - 1),
    storefrontKey: STORE_FRONT_KEY,
    search,
    ...restFilterOptions,
  };

  const { data } = await api.get('/trade/', {
    params: query,
  });

  return {
    artworks: data.results,
    meta: { count: data.count, hasNext: !!data.next, page, artworkData: data.artworkData },
    infiniteLoader,
  };
};

const fetchGroupingArtworksFunc = async ({
  page = 1,
  filterOptions = {},
  search = '',
  infiniteLoader = false,
  offset,
  limit = ARTWORKS_LIMIT,
  slug,
}: FetchGroupingArtworksState) => {
  const { price, inputPrice, ...restFilterOptions } = filterOptions;
  if (filterOptions.inputPrice) {
    if (price) {
      restFilterOptions.price = typeof price === 'string' ? [price, inputPrice] : [...price, inputPrice];
    } else {
      restFilterOptions.price = inputPrice;
    }
  }

  if (price && !inputPrice) {
    restFilterOptions.price = price;
  }

  const query = {
    limit: limit,
    offset: offset || limit * (page - 1),
    storefrontKey: STORE_FRONT_KEY,
    search,
    ...restFilterOptions,
  };

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const { data } = await api.get(`/artworks/groupings/${slug}/`, {
    params: query,
  });
  return {
    artworks: data.results,
    meta: { count: data.count, hasNext: !!data.next, page, artworkData: data.artworkData },
    infiniteLoader,
  };
};

const fetchArtworksFunc = async ({
  page = 1,
  filterOptions = {},
  search = '',
  infiniteLoader = false,
  offset,
  limit = ARTWORKS_LIMIT,
}: FetchArtworksState) => {
  const { price, inputPrice, ...restFilterOptions } = filterOptions;
  if (filterOptions.inputPrice) {
    if (price) {
      restFilterOptions.price = typeof price === 'string' ? [price, inputPrice] : [...price, inputPrice];
    } else {
      restFilterOptions.price = inputPrice;
    }
  }

  if (price && !inputPrice) {
    restFilterOptions.price = price;
  }

  const query = {
    limit: limit,
    offset: offset || limit * (page - 1),
    storefrontKey: STORE_FRONT_KEY,
    search,
    ...restFilterOptions,
  };

  const { data } = await api.get('/artworks/', {
    params: query,
  });

  return {
    artworks: data.results,
    meta: { count: data.count, hasNext: !!data.next, page, artworkData: data.artworkData },
    infiniteLoader,
  };
};

export const fetchArtworks = (prefix: string) => {
  if (prefix === ACTION_PREFIX.GROUPINGS) {
    return createAsyncThunk<FetchArtworksPayload, FetchArtworksState>(
      `${ACTION_PREFIX.GROUPINGS}/FETCH_ARTWORKS`,
      fetchGroupingArtworksFunc
    );
  }
  if (prefix === ACTION_PREFIX.TRADE) {
    return createAsyncThunk<FetchArtworksPayload, FetchArtworksState>(
      `${ACTION_PREFIX.TRADE}/FETCH_ARTWORKS`,
      fetchTradeArtworksFunc
    );
  }
  return createAsyncThunk<FetchArtworksPayload, FetchArtworksState>(
    `${ACTION_PREFIX.ARTWORKS}/FETCH_ARTWORKS`,
    fetchArtworksFunc
  );
};

export const fetchFavoriteArtworks = (prefix: string) =>
  createAsyncThunk<FetchArtworksPayload, FetchArtworksState>(`${prefix}/FETCH_FAVORITE_ARTWORKS`, fetchArtworksFunc);

const postWithBCToken = (url: string, payload?: any) =>
  api.post(url, { storefrontKey: STORE_FRONT_KEY, ...(payload && payload) });

const deleteWithBCToken = (url: string, payload?: any) =>
  api.delete(url, {
    data: { storefrontKey: STORE_FRONT_KEY, ...(payload && payload) },
  });

export const addToFavorite = (prefix: string) =>
  createAsyncThunk<ResponseAddFavoritePayload, RequestAddFavoritePayload>(
    `${prefix}/ADD_TO_FAVORITE`,
    async ({ slug }: { slug: string }) => {
      await postWithBCToken(`/artworks/${slug}/favorites/`);

      return { slug };
    }
  );

export const removeFromFavorite = (prefix: string) =>
  createAsyncThunk<ResponseAddFavoritePayload, RequestAddFavoritePayload>(
    `${prefix}/REMOVE_FROM_FAVORITE`,
    async ({ slug }: { slug: string }) => {
      await deleteWithBCToken(`/artworks/${slug}/favorites/`);

      return { slug };
    }
  );

export const addToWaitlist = (prefix: string) =>
  createAsyncThunk<ResponseAddWaitlistPayload, RequestAddWaitlistPayload>(
    `${prefix}/ADD_TO_WAITLIST`,
    async ({
      email,
      firstName,
      lastName,
      slug,
    }: {
      email: string;
      firstName: string;
      lastName: string;
      slug: string;
    }) => {
      await postWithBCToken(`/artworks/${slug}/waitlist/`, { email, firstName, lastName, slug });

      return { email, firstName, lastName, slug };
    }
  );

export const removeFromWaitlist = (prefix: string) =>
  createAsyncThunk<ResponseRemoveWaitlistPayload, RequestRemoveWaitlistPayload>(
    `${prefix}/REMOVE_FROM_WAITLIST`,
    async ({ slug }: { slug: string }) => {
      await deleteWithBCToken(`/artworks/${slug}/waitlist/`, { slug });

      return { slug };
    }
  );

export const resetArtworks = (prefix: string) => createAction(`${prefix}/RESET_ARTWORKS`);

export const setArtworkDynamicData = (prefix: string) =>
  createAction<ArtworkDynamicData>(`${prefix}/SET_ARTWORK_DYNAMIC_DATA`);

export const setCurrentPage = (prefix: string) => createAction<number>(`${prefix}/SET_CURRENT_PAGE`);
