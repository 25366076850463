import { ParsedUrlQuery } from 'querystring';

import { parseUrl, stringifyUrl } from 'query-string';
import Router, { NextRouter } from 'next/router';
import dayjs from 'dayjs';
import { StatusCodes } from 'http-status-codes';
import {
  pathOr,
  omit,
  isEmpty,
  prop,
  pipe,
  not,
  is,
  equals,
  both,
  ifElse,
  head,
  identity,
  length,
  map,
  propOr,
  T,
  always,
  cond,
  propEq,
  pick,
  values,
  complement,
  either,
  isNil,
  join,
  filter,
  all,
  test,
} from 'ramda';
import { css } from 'styled-components';
import sanitizeHtml from 'sanitize-html';
import { MetaTag } from 'next-seo/lib/types';
import utc from 'dayjs/plugin/utc';
import * as cookie from 'cookie';

import { ImageSet, ImageSetUrl } from '@definitions/common.types';
import { FILTER_ORDER } from '@components/headerFilter/headerFilter.constants';
import { COOKIE_KEY_POSTFIX, HOME_ROUTE, MAIN_ANIMATION, ROUTE } from '@utils/constants';
import { Artwork } from '@definitions/artworks.types';
import reportError from '@utils/reportError';
import { CMS_USER_TYPE } from '@definitions/cmsUserAuth.types';
import { ORDER_STATUS } from '@definitions/order.types';
import { FilterItem } from '@definitions/filters.types';
import {
  DIRECTION,
  NOT_SHIPPED,
  PAGE,
  SELECTED_GALLERIES,
  SHIPPED,
  SORT,
  ORDER_ID,
} from '@modules/orders/orders.constants';

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(utc);

export const addLeadingZero = (number: number) => String(number).padStart(2, '0');

export const updatePageQuery = (index: number, page: number, setCurrentPage: (page: number) => void) => {
  const { href } = location;
  const { url, query } = parseUrl(href);
  const newQuery = page !== 1 ? { ...query, page } : omit(['page'], query);
  const newUrl = stringifyUrl({ url, query: newQuery });

  const pathname = new URL(newUrl).pathname;
  const params = new URL(newUrl).search;

  window.history.replaceState({ ...window.history.state, as: `${pathname}${params}`, url: newUrl }, '', newUrl);
  setCurrentPage(page);
};

export const getImageSrc = (image: ImageSet) => pathOr('', ['urls', '0', 'url'], image);

export const getImageSrcSet = (image: ImageSet) => {
  return image?.urls.map((url: ImageSetUrl) => `${prop('url', url)} ${prop('size', url)}`).join(',');
};

export const getFilterOptionName = (index: number): string => FILTER_ORDER[index];

export const redirectOnUnsubscribeError = (e: any) => {
  const status: StatusCodes = pathOr(500, ['response', 'status'], e);

  if (status === StatusCodes.NOT_FOUND) {
    return {
      redirect: {
        permanent: false,
        destination: `${HOME_ROUTE}?unsubscribe_status=404`,
      },
    };
  }

  return {
    redirect: {
      permanent: false,
      destination: `${ROUTE.ERROR}?status=${status}`,
    },
  };
};

export const redirectOnError = (e: any) => {
  const status: StatusCodes = pathOr(500, ['response', 'status'], e);

  if (status === StatusCodes.NOT_FOUND) {
    return { notFound: true };
  }

  return {
    redirect: {
      permanent: false,
      destination: `${ROUTE.ERROR}?status=${status}`,
    },
  };
};
export const clientRedirectOnError = async (e: any) => {
  const status: StatusCodes = propOr(StatusCodes.INTERNAL_SERVER_ERROR, 'status', e);
  const errorUrl = status === StatusCodes.NOT_FOUND ? ROUTE.NOT_FOUND : `${ROUTE.ERROR}?status=${status}`;

  reportError(e);
  await Router.push(errorUrl);
};

export const formatIntlLink = (link: string) => (chunks: string[]) =>
  sanitizeHtml(`<a href="${link}">${chunks.join('')}</a>`);

// @ts-ignore
export const isNotEmpty = (key: string) => pipe(prop(key), isEmpty, not);

export const getHeaderAnimationProps = (isSearch: boolean) => {
  const primaryMoveX = {
    x: isSearch ? -208 : 0,
  };
  const secondaryMoveX = {
    x: isSearch ? 200 : 0,
  };
  const transition = { duration: MAIN_ANIMATION.DURATION };

  return {
    primary: {
      layoutId: 'primary',
      initial: primaryMoveX,
      animate: {
        ...primaryMoveX,
        transition,
      },
      exit: {
        ...primaryMoveX,
        transition,
      },
    },
    secondary: {
      layoutId: 'secondary',
      initial: secondaryMoveX,
      animate: {
        ...secondaryMoveX,
        transition,
      },
      exit: {
        ...secondaryMoveX,
        transition,
      },
    },
  };
};

export const mapArray = (value: any) => (is(Array, value) ? value : [value]);

export const flatSingleArray = map(ifElse(both(is(Array), pipe(length, equals(1))), head, identity)) as (
  prop: any
) => any;

export const isProd = process.env.NODE_ENV === 'production';
export const isDevOrQA = process.env.NODE_ENV === 'development';

// TODO: this needs to come from BC settings endpoint
export const isMaintenanceMode = false;

export const isLive = process.env.NEXT_PUBLIC_IS_LIVE === 'true';

export const isInSellingPeriod = ({ liveStartAt, liveEndAt }: { liveStartAt: string; liveEndAt: string }) =>
  dayjs().isAfter(dayjs(liveStartAt)) && dayjs().isBefore(dayjs(liveEndAt));

export const getShowAddToBag = ({
  inventoryLevel = 0,
  addToWaitlist,
  liveStartAt,
  liveEndAt,
  isExclusive,
  isShoppable = false,
  isTrade,
}: Pick<Artwork, 'addToWaitlist' | 'liveStartAt' | 'liveEndAt' | 'isTrade'> & {
  isExclusive: boolean;
  isShoppable: boolean;
  inventoryLevel?: number;
}) => {
  if (isExclusive) {
    if (isShoppable) {
      if (addToWaitlist) return false;
      return inventoryLevel > 0 && dayjs().isBefore(dayjs(liveEndAt));
    }
    return false;
  }

  if (isTrade && inventoryLevel > 0) {
    return true;
  }

  if (inventoryLevel === 0 || addToWaitlist) {
    return false;
  }

  if (isExclusive) {
    return dayjs().isBefore(dayjs(liveEndAt));
  }

  return isInSellingPeriod({ liveEndAt, liveStartAt });
};

export const isWhiteColor = (color: string | undefined) => {
  if (color) {
    const lowercaseColor = color.toLowerCase();
    return (
      lowercaseColor.includes('rgba(255, 255, 255') || lowercaseColor.includes('#ffffff') || lowercaseColor === '#fff'
    );
  }

  return false;
};

export const isBrowser = () => typeof window !== 'undefined';

export const isDev = process.env.NODE_ENV === 'development';

export const roundNumber = (number: number) => Math.round((number + Number.EPSILON) * 100) / 100;

export const formatNumber = (number: string | number, fractionDigits = 2) =>
  pipe(ifElse(is(Number), identity, parseFloat), (number: number) => number.toFixed(fractionDigits))(number);

export const formatPrice = (price: string | number, prefixCurrency = false) => {
  const formattedPrice =
    Number(price) >= 10000 ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : price.toString();

  return prefixCurrency ? `$${formattedPrice}` : `${formattedPrice}$`;
};

export const formatCartPrice = (price: string | number) => formatPrice(formatNumber(price), true);

export const formatStorePrice = (price: string | number) => formatPrice(formatNumber(price, 0), true);

export const getMetaTags = (tags: MetaTag[] = []) => {
  const overwriteTagsProps = [
    { name: 'og:description', attr: 'property', key: 'ogDescription' },
    { name: 'og:title', attr: 'property', key: 'ogTitle' },
    { name: 'description', attr: 'name', key: 'description' },
  ];

  return tags.reduce<{ additionalMetaTags: MetaTag[]; ogDescription?: string; ogTitle?: string; description?: string }>(
    (prev, next) => {
      const tagProps = overwriteTagsProps.find((data) => propOr('', data.attr, next) === data.name);
      return tagProps
        ? { ...prev, [tagProps.key]: next.content }
        : { ...prev, additionalTags: prev.additionalMetaTags.push(next) };
    },
    { additionalMetaTags: [] }
  );
};

export const formatDate = (date: string) => dayjs(date).utc(true).format('YYYY-MM-DD HH:mm');

export const getParsedFedexDate = (date: dayjs.Dayjs) =>
  cond([
    [equals(6), () => dayjs(date).add(2, 'day').format('YYYY-MM-DD')],
    [equals(0), () => dayjs(date).add(1, 'day').format('YYYY-MM-DD')],
    [T, always(date.format('YYYY-MM-DD'))],
    // @ts-ignore
  ])(dayjs(date).day());

export const getLongestWordLength = (sentence: string) =>
  Math.max(...sentence.split(' ').map((word: string) => word.length));

export const isOrderCancelled = propEq('shippingStatus', ORDER_STATUS.CANCELLED);

export const getPreviewToken = (cookies: string | undefined) => {
  try {
    if (!cookies) {
      return null;
    }

    const parsedCookies = cookie.parse(cookies);
    const persistCookie = JSON.parse(parsedCookies[`persist%3Aroot${COOKIE_KEY_POSTFIX}`]) as { cmsUserAuth: string };
    const cmsAuthUserState = JSON.parse(persistCookie.cmsUserAuth);
    const { token, role } = cmsAuthUserState as { token: string; role: string };

    if (token && role === CMS_USER_TYPE.PREVIEW) {
      return `Bearer ${token}`;
    }

    return null;
  } catch (e) {
    return null;
  }
};

export const getFullArtistName = pipe(
  pick(['firstName', 'middleName', 'lastName']),
  values,
  filter(complement(either(isNil, isEmpty))),
  join(' ')
);

export const preventTabIndex = ifElse(equals(true), always(-1), always(0));

export const getHasProducts = both(
  complement(isEmpty),
  complement(
    all(
      pipe(ifElse(propEq('type', 'productsWithTitle'), pathOr([], ['value', 'products']), propOr([], 'value')), isEmpty)
    )
  )
);

export const hasEmptyFilters = (filterOptions: FilterItem[], query: ParsedUrlQuery) =>
  pipe(pick([...map(prop('name'), filterOptions), 'sort', 'direction']), isEmpty)(query);

// @ts-ignore
export const getGalleryQuery: (query: { [key: string]: any }) => { [key: string]: string | boolean | number } = pipe(
  // @ts-ignore
  pick([NOT_SHIPPED, SHIPPED, DIRECTION, SORT, PAGE, SELECTED_GALLERIES, ORDER_ID]),
  map(ifElse(test(/^\d+$|^true$|^false$/), (value: string) => JSON.parse(value), identity))
);

export const changeUrlWithoutRedirection = (
  { push, pathname }: NextRouter,
  query: { [key: string]: string | string[] | number | boolean | number[] }
) => push({ pathname, query }, undefined, { shallow: true });

export const getFirstParam = (param: string | string[] | undefined | null) => {
  if (!param) {
    return '';
  }

  if (typeof param === 'object') {
    return param[0];
  }

  return param;
};

export const createArrayIfNot = (param: string | string[] | undefined | null) => {
  if (!param) {
    return [];
  }

  if (typeof param === 'string') {
    return [param];
  }

  return param;
};

export const ellipsisText = (text: string, maxLength = 50): string => {
  if (text && text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  } else {
    return text;
  }
};

// localStorage helper
export const ls = {
  get: (item: string) => {
    const i = window.localStorage.getItem(item);
    return i && JSON.parse(i);
  },
  set: (item: string, value: any) => window.localStorage.setItem(item, JSON.stringify(value)),
  remove: (item: string) => window.localStorage.removeItem(item),
};

export enum TRANSITIONS {
  ALL = 'all',
  OPACITY = 'opacity',
  TRANSFORM = 'transform',
}

export enum DURATIONS {
  MS_100 = '0.1s',
  MS_200 = '0.2s',
  MS_300 = '0.3s',
}

export enum EASINGS {
  IN_OUT = 'ease-in-out',
}

export type TransitionProps = {
  properties: string[];
  duration: string;
  easing: string;
};

const defaultParameters = {
  properties: [TRANSITIONS.ALL],
  duration: DURATIONS.MS_200,
  easing: EASINGS.IN_OUT,
};

export const transition = (options: TransitionProps = defaultParameters) => css`
  transition: ${options.properties.map(
    (property, index) =>
      `${property} ${options.duration} ${options.easing} ${index !== options.properties.length - 1 ? ',' : ''}`
  )};
`;

export const getMaximumUnitsToPurchase = (maximumUnits: number) => {
  // Even when maximumUnitsPerCart is 0 we should return 1 to enable purchasability;
  if (maximumUnits === 0) return 1;
  return maximumUnits;
};

export const capitalizeWords = (str: string) => {
  return str.toLowerCase().replace(/(?:^|\s)\S/g, function (char) {
    return char.toUpperCase();
  });
};
