export const CUSTOMER_FORM_FIRST_NAME = 'firstName';
export const CUSTOMER_FORM_LAST_NAME = 'lastName';
export const CUSTOMER_FORM_PHONE = 'phoneNumber';
export const CUSTOMER_FORM_EMAIL = 'email';
export const CUSTOMER_FORM_PASSWORD = 'password';
export const CUSTOMER_FORM_CONFIRM_PASSWORD = 'confirmPassword';
export const CUSTOMER_FORM_CURRENT_PASSWORD = 'currentPassword';
export const CUSTOMER_FORM_TERMS = 'terms';
export const CUSTOMER_FORM_TERMS_AND_NEWSLETTER = 'termsAndNewsletterAccepted';
export const CUSTOMER_FORM_TERMS_ONLY = 'termsOnlyAccepted';
export const CUSTOMER_FORM_SUBSCRIBE_NEWSLETTER = 'subscribeNewsletter';
export const CUSTOMER_FORM_TERMS_ACCEPTED = 'termsAndConditionsAccepted';
export const CUSTOMER_FORM_ID = 'id';

export const ADDRESS_FORM_FIRST_NAME = 'firstName';
export const ADDRESS_FORM_LAST_NAME = 'lastName';
export const ADDRESS_FORM_COMPANY = 'company';
export const ADDRESS_FORM_ADDRESS_1 = 'address1';
export const ADDRESS_FORM_ADDRESS_2 = 'address2';
export const ADDRESS_FORM_COUNTRY = 'country';
export const ADDRESS_FORM_COUNTRY_CODE = 'countryCode';
export const ADDRESS_FORM_STATE = 'stateOrProvince';
export const ADDRESS_FORM_CITY = 'city';
export const ADDRESS_FORM_POSTAL_CODE = 'postalCode';
export const ADDRESS_FORM_PHONE = 'phone';
