import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { Cart, ShoppingCartState } from '@definitions/shoppingCart.types';
import { QueryParam } from '@definitions/common.types';

import * as actions from './shoppingCart.actions';

export const initialState: ShoppingCartState = {
  cart: {
    lineItems: { giftCertificates: [], physicalItems: [] },
    id: '',
    cartAmount: 0,
    customerId: 0,
    baseAmount: 0,
    channelId: 0,
    createdTime: '',
    discountAmount: 0,
    currency: { code: 'USD' },
    email: '',
    updatedTime: '',
    taxIncluded: true,
  },
  cartId: null,
  isRecoveredCart: false,
};

const handleUpdateCartItem = (state: ShoppingCartState, { payload }: PayloadAction<Cart>) => {
  state.cart = payload;
  state.cartId = payload.id;
  state.isRecoveredCart = false;
};

const handleAddCartItem = (state: ShoppingCartState, { payload }: PayloadAction<Cart>) => {
  state.cart = payload;
  state.cartId = payload.id;
  state.isRecoveredCart = false;
};

const handleRemoveCartItem = (state: ShoppingCartState, { payload }: PayloadAction<Cart | null>) => {
  if (payload) {
    state.cart = payload;
    return;
  }

  state.cart = initialState.cart;
  state.cartId = null;
  state.isRecoveredCart = false;
};

const handleFetchCart = (
  state: ShoppingCartState,
  { payload: { cart, cartRecoveryId } }: PayloadAction<{ cart: Cart | null; cartRecoveryId: QueryParam }>
) => {
  if (cart) {
    state.cart = cart;
    if (cartRecoveryId) {
      state.cartId = typeof cartRecoveryId === 'object' ? cartRecoveryId[0] : cartRecoveryId;
      state.isRecoveredCart = true;
    }
  }
};

const handleRejectedFetchCart = (state: ShoppingCartState, { payload: { cartRecoveryId } }: PayloadAction<any>) => {
  state.cart = initialState.cart;
  state.cartId = null;
  if (cartRecoveryId) {
    state.isRecoveredCart = true;
  }
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actions.addCartItem.fulfilled, handleAddCartItem);
  builder.addCase(actions.updateCartItem.fulfilled, handleUpdateCartItem);
  builder.addCase(actions.removeCartItem.fulfilled, handleRemoveCartItem);
  builder.addCase(actions.fetchCart.fulfilled, handleFetchCart);
  builder.addCase(actions.fetchCart.rejected, handleRejectedFetchCart);
});
