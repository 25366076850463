import { pick } from 'ramda';
import Cookies from 'js-cookie';

import nextAPI from '@services/nextAPI';
import reportError from '@utils/reportError';
import { Cart } from '@definitions/shoppingCart.types';
import { OMNISEND_CONTACT_ID_COOKIE, ROUTE } from '@utils/constants';
import { getFullArtistName } from '@utils/helpers';

import { CART_RECOVERY_PARAM, isOmnisendCartActive } from './shoppingCart.constants';

const getOmnisendClient = async () => {
  try {
    const {
      data: {
        data: { customer },
      },
    } = await nextAPI.get('bigcommerce/customers');

    return { email: customer.email };
  } catch (e) {
    const contactID = Cookies.get(OMNISEND_CONTACT_ID_COOKIE);
    if (contactID) {
      return { contactID };
    }

    return {};
  }
};

export const createUpdateAbandonedCart = async (cart: Cart) => {
  try {
    if (!isOmnisendCartActive) {
      return;
    }

    const { contactID = null, email = null } = await getOmnisendClient();

    if (contactID || email) {
      const additionalData = email ? { email } : { contactID };
      const { physicalItems } = cart.lineItems;
      const products = physicalItems.map(({ productId, artist, slug, ...rest }) => ({
        description: getFullArtistName(artist),
        productUrl: `${location.origin}${ROUTE.ARTWORKS}/${artist.slug}/${slug}`,
        productId,
        ...pick(['id', 'variantId', 'name', 'quantity', 'salePrice', 'imageUrl'], rest),
      }));

      await nextAPI.put(`email/carts/${cart.id}`, {
        products,
        currency: cart.currency.code,
        baseAmount: cart.baseAmount,
        cartRecoveryUrl: `${location.origin}?${CART_RECOVERY_PARAM}=${cart.id}`,
        ...additionalData,
      });
    }
  } catch (e) {
    reportError(e);
  }
};

export const removeAbandonedCart = async (cartId: string) => {
  try {
    if (!isOmnisendCartActive) {
      return;
    }

    await nextAPI.delete(`email/carts/${cartId}`);
  } catch (e) {
    reportError(e);
  }
};
