import { createSelector } from '@reduxjs/toolkit';

import { Cart, ShoppingCartState } from '@definitions/shoppingCart.types';
import { initialState } from '@modules/shoppingCart/index';

import { GlobalState } from '../createStore';

export const selectShoppingCartDomain = (state: GlobalState) => state.shoppingCart;

export const selectCart = createSelector(
  selectShoppingCartDomain,
  (state: ShoppingCartState) => state.cart || initialState.cart
);
export const selectCartId = createSelector(selectShoppingCartDomain, (state: ShoppingCartState) => state.cartId);
export const selectIsRecoveredCart = createSelector(
  selectShoppingCartDomain,
  (state: ShoppingCartState) => state.isRecoveredCart
);
export const selectLineItems = createSelector(selectCart, (state: Cart) => state.lineItems);
