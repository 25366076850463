import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '@services/api';

const PREFIX = 'FOOTER';

export const fetchFooterLinks = createAsyncThunk(`${PREFIX}/FETCH_FOOTER_LINKS`, async () => {
  const { data } = await api.get('footer/');

  return data;
});
