export enum CONSENT_OPTION {
  ALLOW = 'allow',
  DENY = 'deny',
}

export enum COOKIE_TYPES {
  PERFORMANCE_COOKIES = 'performanceCookies',
  OTHER_COOKIES = 'otherCookies',
}

export interface CookieTypes {
  [COOKIE_TYPES.PERFORMANCE_COOKIES]: boolean;
  [COOKIE_TYPES.OTHER_COOKIES]: boolean;
}

export interface GdprConsentsState {
  showModal: boolean;
  gdprConsent?: CONSENT_OPTION;
  gdprConsents: CookieTypes;
}
