import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { COOKIE_TYPES, CookieTypes, GdprConsentsState } from '@definitions/gdprConsents.types';

import * as actions from './gdprConsents.actions';

export const initialState: GdprConsentsState = {
  showModal: true,
  gdprConsents: {
    [COOKIE_TYPES.PERFORMANCE_COOKIES]: false,
    [COOKIE_TYPES.OTHER_COOKIES]: false,
  },
};

const handleSetConsents = (state: GdprConsentsState, { payload }: PayloadAction<CookieTypes>) => {
  state.showModal = false;
  state.gdprConsents = payload;
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actions.setConsents, handleSetConsents);
});
